export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');



        // $('.testimonial__slider').slick({
        //     dots: false,
        //     infinite: true,
        //     speed: 400,
        //     fade: false,
        //     cssEase: 'linear',
        //     autoplay: true,
        //     slidesToShow: 5,
        //     draggable: true,
        //     arrows: false,
        //     swipeToSlide: true,
        //     responsive: [
        //         {
        //             breakpoint: 1200,
        //             settings: {
        //                 slidesToShow: 4,
        //             },
        //         },
        //         {
        //             breakpoint: 992,
        //             settings: {
        //                 slidesToShow: 3,
        //             },
        //         },
        //         {
        //             breakpoint: 768,
        //             settings: {
        //                 slidesToShow: 2,
        //             },
        //         },
        //         {
        //             breakpoint: 576,
        //             settings: {
        //                 slidesToShow: 2,
        //             },
        //         },
        //     ],
        // });

        if ($(window).width() < 768) {
            $('.layer__licentiehouders .row').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: false,
                cssEase: 'linear',
                autoplay: true,
                slidesToShow: 2,
                draggable: true,
                arrows: true,
                swipeToSlide: true,
                prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#11928d" d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></span>',
                nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#11928d" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></span>',
            });

            $('.front__news .row').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: false,
                cssEase: 'linear',
                autoplay: true,
                slidesToShow: 2,
                draggable: true,
                arrows: true,
                swipeToSlide: true,
                prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#11928d" d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></span>',
                nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#11928d" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></span>',
            });
        }

        $('.goto--top').click(function (event) {
            event.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, 500);
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
